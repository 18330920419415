body {
  background-image : url('./img/app/background.png');
}

.app-wrapper {
  background-color: rgba(255, 255, 255, .9);
}

@media (min-width: 768px) {
  .body-wrapper {
    height: 85vh;
    margin-top: 10vh;
    padding-top: 10px;
  }
  .card-info-wrapper {
    height: 100%;
  }
  .card-info {
    height: 100%;
    overflow: scroll;
  }
}

@media (max-width: 768px) {
  .body-wrapper {
    margin-top: 10vh;
  }
}

.focused-card-wrapper img {
  padding-bottom: 10px;
  padding-top: 10px;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.right-column-wrapper {
  height: 100%;
  overflow: scroll;
}